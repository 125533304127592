<template>
	<v-sheet>
		<v-layout>
			<v-flex md12 class="px-4 mt-2 grey lighten-4">
				<v-tabs
					v-model="dataTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent border-bottom w-100"
					active-class="blue darken-4 white--text"
					hide-slider
				>
					<v-tab href="#tab_1"> <v-icon small left>mdi-google-analytics</v-icon>Google Analytics</v-tab>
					<v-tab href="#tab_2">
						<v-icon small left>mdi-feature-search</v-icon>Google Search Console</v-tab
					>
				</v-tabs>
			</v-flex>
		</v-layout>

		<div class="px-4">
			<v-tabs-items v-model="dataTab" class="transparent">
				<v-tab-item value="tab_1">
					<GoogleAnalyticsTab1
						:filters="analytics_filter"
						@toggleFilters="toggleFiltersAnalytics"
						:page-loading="pageLoading"
						@pageLoading="pageLoading = $event"
						:analytics-data="analytics_data"
					/>
				</v-tab-item>
				<v-tab-item value="tab_2">
					<GoogleSearchConsoleTab2
						:filters="gsc_filter"
						@toggleFilters="toggleFiltersGSC"
						:page-loading="pageLoading"
						@pageLoading="pageLoading = $event"
						:search-console-data="search_console_data"
					/>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</v-sheet>
</template>
<script>
import MainMixin from "@/core/mixins/main.mixin.js";
import GoogleAnalyticsTab1 from "./Tabs/GoogleAnalyticsTab1.vue";
import GoogleSearchConsoleTab2 from "./Tabs/GoogleSearchConsoleTab2.vue";
import { QUERY } from "@/core/services/store/request.module";

export default {
	name: "Analytics",
	mixins: [MainMixin],
	components: {
		GoogleAnalyticsTab1,
		GoogleSearchConsoleTab2,
	},
	props: {
		seoId: {
			type: [String, Number],
			default: 0,
		},
	},
	data() {
		return {
			loading: false,
			dataTab: "tab_1",
			analytics_filter: {
				start_date: null,
				end_date: null,
			},
			gsc_filter: {
				start_date: null,
				end_date: null,
			},
			analytics_data: null,
			search_console_data: null,
			pageLoading: false,
		};
	},
	mounted() {
		this.getGoogleAnalytics();
		this.getGoogleSearchConsole();
	},
	computed: {},
	methods: {
		toggleFiltersAnalytics(event) {
			const { start_date, end_date } = event;
			this.analytics_filter.start_date = start_date || null;
			this.analytics_filter.end_date = end_date || null;
			this.getGoogleAnalytics();
		},
		toggleFiltersGSC(event) {
			const { start_date, end_date } = event;
			this.gsc_filter.start_date = start_date || null;
			this.gsc_filter.end_date = end_date || null;
			this.getGoogleSearchConsole();
		},
		getGoogleAnalytics() {
			const SEO_ID = this.seoId;
			const PAYLOAD = {
				start_date: this.analytics_filter.start_date || null,
				end_date: this.analytics_filter.end_date || null,
			};
			const _this = this;
			_this.pageLoading = true;
			/* Set Filters in Query Params */
			_this.setQuery(PAYLOAD, "analytics");
			_this.$store
				.dispatch(QUERY, { url: `google-analytics-data/${SEO_ID}`, data: PAYLOAD })
				.then((data) => {
					if (data && Object.keys(data).length) {
						this.analytics_data = data;
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getGoogleSearchConsole() {
			const SEO_ID = this.seoId;
			const PAYLOAD = {
				start_date: this.gsc_filter.start_date || null,
				end_date: this.gsc_filter.end_date || null,
			};
			const _this = this;
			_this.pageLoading = true;
			_this.setQuery(PAYLOAD, "gsc");
			_this.$store
				.dispatch(QUERY, { url: `google-search-console-data/${SEO_ID}`, data: PAYLOAD })
				.then((data) => {
					if (data && Object.keys(data).length) {
						this.search_console_data = data;
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		setQuery(payload, type) {
			const { query, param, name } = this.$route;

			const Query =
				type == "analytics"
					? { ...query, a_start: payload.start_date, a_end: payload.end_date }
					: { ...query, gsc_start: payload.start_date, gsc_end: payload.end_date };

			this.$router.push({
				name,
				query: Query,
				param,
			});
		},
	},
	watch: {
		dataTab(param) {
			const { query, params } = this.$route;
			if (param) {
				this.$router.push({ name: "seo-detail", query: { ...query, subTab: param }, params });
			}
		},
	},
	beforeMount() {
		const { query } = this.$route;
		/* const { subTab, a_start, a_end, gsc_start, gsc_end } = query; */

		this.analytics_filter = {
			start_date: query.a_start || null,
			end_date: query.a_end || null,
		};
		this.gsc_filter = {
			start_date: query.gsc_start || null,
			end_date: query.gsc_end || null,
		};

		this.dataTab = query.subTab || "tab_1";
	},
};
</script>
